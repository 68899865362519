import React, { forwardRef } from 'react';
import clsx from 'clsx';

export interface PartnerLogoProps
    extends React.ImgHTMLAttributes<HTMLImageElement> {
    src: string;
    alt: string;
}

const PartnerLogo = forwardRef<HTMLImageElement, PartnerLogoProps>(
    ({ alt, children, className, src, ...props }, ref) => {
        return (
            <img
                src={src}
                alt={alt}
                className={clsx('max-h-6/10', className)}
                ref={ref}
                {...props}
            />
        );
    }
);

PartnerLogo.defaultProps = {
    src: '',
    alt: ''
};

export default PartnerLogo;
