import clsx from 'clsx';
import React, { FC } from 'react';

import { Button } from '../../../components/navigation/button';

export interface SlickNavProps extends React.HTMLAttributes<HTMLDivElement> {
    dots: any;
    slider: any;
}

const SlickNav: FC<SlickNavProps> = ({
    children,
    className,
    dots,
    slider,
    ...props
}) => {
    const prev = () => {
        slider.current.slickPrev();
    };

    const next = () => {
        slider.current.slickNext();
    };

    return (
        <div
            className={clsx(
                'slick-custom-dots',
                'flex justify-center items-center mt-4',
                't-sm:justify-between'
            )}
            {...props}>
            <ul className="hidden t-sm:flex t-sm:items-center">{dots}</ul>
            <div className="flex items-center">
                <Button
                    onClick={() => prev()}
                    arrowFlipped={true}
                    variant="signage"
                    className="mr-1"
                />
                <Button onClick={() => next()} variant="signage" />
            </div>
        </div>
    );
};

SlickNav.defaultProps = {};

export default SlickNav;
