import React, { FC } from 'react';
import clsx from 'clsx';

export interface SlickDotProps extends React.HTMLAttributes<HTMLDivElement> {}

const SlickDot: FC<SlickDotProps> = ({ children, className, ...props }) => {
    return (
        <div
            className={clsx('h-1 w-1 rounded-full bg-secondary', className)}
            {...props}
        />
    );
};

SlickDot.defaultProps = {};

export default SlickDot;
